<template>
  <div class="home">
    <router-view/>
    <van-tabbar v-model="active" route>
      <van-tabbar-item to="/" >
        <span>校招职位</span>
        <template #icon="props">
          <img :src="props.active ? icon.active : icon.inactive" />
        </template>
      </van-tabbar-item>
      <van-tabbar-item to="/alllist" icon="search">菜单栏</van-tabbar-item>
      <van-tabbar-item to="/schoolmove">
        <span>个人信息</span>
        <template #icon="props">
          <img :src="props.active ? icon.active : icon.inactive" />
        </template>
      </van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script>
export default {
  name: 'HomeView',
  data () {
    return {
      active: 0,
      icon: {
        active: 'https://img01.yzcdn.cn/vant/user-active.png',
        inactive: 'https://img01.yzcdn.cn/vant/user-inactive.png'
      }
    }
  },
  methods: {
  },
  mounted () {
    document.title = '校招职位'
  }
}
</script>

<style scoped lang="less">
.home {
  min-height: 100vh;
}
</style>
